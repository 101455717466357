import React from "react";
import { Flex, Heading, Divider, Text } from "@chakra-ui/react";

interface Props {
  subtitulo?: String;
  titulo: string;
}
export default function TitleDefault(props: Props) {
  return (
    <Flex flexDir="column" w="full">
      {props.subtitulo && (
        <Text color="#017DC5" fontSize="14px" textTransform="uppercase" mb={4}>
          {props.subtitulo}
        </Text>
      )}
      {props.titulo && (
        <>
          <Heading
            as="h2"
            fontSize="24px"
            color="#101010"
            pr={["0", "0", "0", "40%"]}
          >
            {props.titulo}
          </Heading>
          <Divider
            bgColor="#ED1B2F"
            height={1}
            borderRadius={4}
            maxW="40px"
            mt={4}
            mb={8}
          />
        </>
      )}
    </Flex>
  );
}
