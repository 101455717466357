import { Flex } from "@chakra-ui/react";
import { IGeneralSettings } from "../../interfaces/GeneralSettings";
import { INavegation, INavFooter } from "../../interfaces/Navagation";
import { ModalRepresentanteProvider } from "../../provider/modalRepresentantes";
import Footer from "../Footer";

import Header from "../Header";
import Modal from "../Modal";

const Layout: React.FC<React.PropsWithChildren<IGeneralSettings & INavegation & INavFooter>> = ({
  children,
  generalSettings,
  menu,
  menuFooter,
}) => (
  <ModalRepresentanteProvider>
    <Flex flexDir="column" overflowX="hidden">
      <Modal generalSettings={generalSettings} />
      <Header generalSettings={generalSettings} menu={menu} />
      <Flex as="main" w="full" flexDir="column" zIndex="9">
        {children}
      </Flex>
      <Footer generalSettings={generalSettings} menuFooter={menuFooter} />
    </Flex>
  </ModalRepresentanteProvider>
);

export default Layout;
