import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
});

const theme = extendTheme({
  breakpoints,
  fonts: {
    heading: "Fira Sans",
    body: "Fira Sans",
  },
  components: {
    Button: { baseStyle: { _focus: { boxShadow: "none" } } },
    Link: { baseStyle: { _focus: { boxShadow: "none" } } },
  },
});
export default theme;
