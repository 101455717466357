import React, { useState } from "react";
import Link from "next/link";
import TopBar from "./TopBar";
import { IGeneralSettings } from "../../interfaces/GeneralSettings";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { getMedia } from "../../lib/media";
import IconSerach from "../../assets/icons/icon-search.svg";
import Navegation from "./Navegation";
import { INavegation } from "../../interfaces/Navagation";
import { HamburgerIcon } from "@chakra-ui/icons";
import MobileMenu from "./Navegation/mobileMenu";

export default function Header({
  generalSettings,
  menu,
}: IGeneralSettings & INavegation) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TopBar generalSettings={generalSettings} />
      <Flex
        bg="radial-gradient(37.15% 309.79% at 17.94% 50%, #007DC5 0%, #00377B 100%)"
        py={6}
      >
        <Flex
          flex="1"
          flexDir={["column", "column", "row"]}
          alignItems="center"
          justifyContent="space-between"
          maxW={["80%", "80%", "90%", "90%", "1170px"]}
          mx="auto"
        >
          <Flex
            justifyContent="space-between"
            w="full"
            alignItems="center"
            mb={[5, 5, 0]}
          >
            <Link href="/" passHref>
              <a>
                <img src={getMedia(generalSettings.logo)} />
              </a>
            </Link>
            <HamburgerIcon
              fontSize="40px"
              color="white"
              display={["flex", "flex", "none"]}
              onClick={() => setIsOpen(true)}
            />
          </Flex>
          <Box w={["full", "full", "auto"]}>
            <form method="get" action="/busca">
              <InputGroup>
                <Input
                  minW={["auto", "auto", "290px", "290px"]}
                  borderWidth="2px"
                  color="#FFF"
                  type="text"
                  name="s"
                  placeholder="O que você está procurando?"
                  _placeholder={{ color: "#FFF" }}
                  _focus={{ borderColor: "#FFF" }}
                  _hover={{ borderColor: "#FFF" }}
                />
                <InputRightElement cursor="pointer">
                  <Button variant="link" textAlign="center" type="submit">
                    <IconSerach />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </form>
          </Box>
        </Flex>
      </Flex>
      <Navegation menu={menu} />
      <MobileMenu
        menu={menu}
        setIsOpenMenu={setIsOpen}
        isOpenMenu={isOpen}
        generalSettings={generalSettings}
      />
    </>
  );
}
