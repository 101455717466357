import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Link,
  Select,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import RepMsgIcon from "../../assets/icons/icon-rep-msg.svg";
import RepUserIcon from "../../assets/icons/icon-rep-user.svg";
import RepWhatsIcon from "../../assets/icons/icon-rep-whats.svg";
import fetchAPI from "../../lib/api";
import {
  filtrarCidade,
  filtrarEstado,
  filtrarRepresentantes,
} from "../../lib/products";
import { useModalRepresentante } from "../../provider/modalRepresentantes";
import TitleDefault from "../TitleDefault";

type Estado = {
  id: number;
  nome: string;
};

type Cidade = {
  id: number;
  nome: string;
};

type Bairro = {
  id: number;
  nome: string;
};

interface RepresentanteProps {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  endereco: string;
  tipo: string;
  representante_estado: Estado;
  representante_cidade: Cidade;
  representante_bairro: Bairro;
}

type Props = {
  tipo: "representante" | "loja";
};

export default function Representantes({ tipo }: Props) {
  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });
  const {
    setTotalResult,
    setFilterEstadoNome,
    setFilterCidadeNome,
    setFiltered,
    filtered,
  } = useModalRepresentante();
  const [cidades, setCidades] = useState({} as Cidade[]);
  const [estados, setEstados] = useState({} as Estado[]);
  const [bairros, setBairros] = useState({} as Bairro[]);
  const [representantes, setRepresentantes] = useState(
    [] as RepresentanteProps[]
  );
  const [filterEstado, setFilterEstado] = useState<string>("all");
  const [filterCidade, setFilterCidade] = useState<string>("all");
  const [filterBairro, setFilterBairro] = useState<string>("all");
  const [cidadeActive, setCidadeActive] = useState<boolean>(false);
  const [bairroActive, setBairroActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const estados = await fetchAPI("/representante-estados?_sort=nome:ASC");
      setEstados(estados);
      setIsLoading(false);
    })();
  }, []);

  const handleEstado = async (e: any) => {
    setIsLoading(true);
    setCidadeActive(false);
    setFilterEstado(e.target.value);
    const cidades = await filtrarEstado(e.target.value, tipo);
    setIsLoading(false);
    setCidades(cidades);
    setFilterEstadoNome(e.target.options[e.target.selectedIndex].text);
    setCidadeActive(true);
    setBairroActive(false);
  };

  const handleCidade = async (e: any) => {
    setIsLoading(true);
    setFilterCidade(e.target.value);
    const bairros = await filtrarCidade(filterEstado, e.target.value, tipo);
    setIsLoading(false);
    setBairros(bairros);
    setFilterCidadeNome(e.target.options[e.target.selectedIndex].text);
    setBairroActive(true);
  };

  const handleBairro = async (e: any) => {
    setFilterBairro(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const res = await filtrarRepresentantes(
      filterEstado,
      filterCidade,
      filterBairro,
      tipo
    );
    setIsLoading(false);
    setRepresentantes(res);
    setTotalResult(res.length);
    setFiltered(true);
    setFilterEstado("all");
    setFilterCidade("all");
    setFilterBairro("all");
  };

  // function to create whatsapp link
  const createWhatsappLink = (phone: string, text: string) => {
    const phoneNumber = phone.replace(/\D/g, "");
    const link = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(
      text
    )}`;
    return link;
  };

  // Slide
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const sliderRef = useRef<any>(null);

  return (
    <>
      {filtered === false ? (
        <Flex
          w={["100%", "100%", "72%"]}
          mr={["0", "0", "5%"]}
          pl={["10%", "10%", "5%"]}
          pr={["10%", "10%", "0"]}
          alignSelf="flex-end"
          flexDir="column"
        >
          <TitleDefault
            titulo={
              tipo === "representante"
                ? "Encontre um representante"
                : "Encontre uma loja perto de você"
            }
          />
          {tipo === "representante" ? (
            <Text>Encontre um Representante HERC perto de você!</Text>
          ) : (
            <>
              <Text>
                Quer saber qual a loja mais próxima para encontrar os produtos
                HERC?
              </Text>
              <Text>
                Preencha os campos abaixo e encontre o ponto de venda mais
                próximo de você.
              </Text>
            </>
          )}

          <Flex mt="40px" flexDir={["column", "column", "row"]}>
            <Box mr={2} mb={[4, 4, 0]}>
              <Select
                borderRadius="8px"
                borderColor="#EFEFF1"
                iconColor="#ED1B2F"
                fontSize="14px"
                onChange={handleEstado}
              >
                <option value="all">Todos Estados</option>
                {estados.length > 0 &&
                  estados.map((estado) => (
                    <option key={estado.id} value={estado.id}>
                      {estado.nome}
                    </option>
                  ))}
              </Select>
            </Box>
            <Box mr={2} mb={[4, 4, 0]}>
              <Select
                borderRadius="8px"
                borderColor="#EFEFF1"
                iconColor="#ED1B2F"
                fontSize="14px"
                onChange={handleCidade}
                disabled={cidadeActive ? false : true}
              >
                <option value="all">Todas Cidades</option>
                {cidades.length > 0 &&
                  cidades.map((cidade) => (
                    <option key={cidade.id} value={cidade.id}>
                      {cidade.nome}
                    </option>
                  ))}
              </Select>
            </Box>
            <Box mr={2} mb={[4, 4, 0]}>
              <Select
                borderRadius="8px"
                borderColor="#EFEFF1"
                iconColor="#ED1B2F"
                fontSize="14px"
                onClick={handleBairro}
                disabled={bairroActive ? false : true}
              >
                <option value="all">Todos Bairros</option>
                {bairros.length > 0 &&
                  bairros.map((bairro) => (
                    <option key={bairro.id} value={bairro.id}>
                      {bairro.nome}
                    </option>
                  ))}
              </Select>
            </Box>
            <Box ml={2} textAlign={["center", "center", "left"]}>
              <Button
                p="10px 30px"
                bgColor="#007DC5"
                borderRadius="12px"
                color="white"
                fontSize="14px"
                fontWeight="bold"
                _hover={{
                  bgColor: "#00377B",
                }}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Buscar
              </Button>
            </Box>
          </Flex>
        </Flex>
      ) : (
        <Flex
          w={["100%", "100%", "62%", "62%", "62%", "72%"]}
          mr={["0", "0", "5%"]}
          alignItems="center"
          flexDir="row"
        >
          {representantes.length > 4 && !isSmallerThan768 ? (
            <>
              <ChevronLeftIcon
                onClick={() => {
                  sliderRef.current.slickPrev();
                }}
                color="#007DC5"
                fontSize="3em"
                cursor="pointer"
                opacity={0.5}
                _hover={{ opacity: 1 }}
              />
              <Flex flexDir="column" w="full">
                <Slider ref={sliderRef} {...settings}>
                  {representantes &&
                    representantes.map((representante: RepresentanteProps) => (
                      <Flex key={representante.id} px={2}>
                        <Box
                          key={representante.id}
                          as="div"
                          w="full"
                          textAlign="center"
                        >
                          <Flex
                            bgColor="#007DC5"
                            borderRadius="full"
                            w="75px"
                            h="75px"
                            justifyContent="center"
                            alignItems="center"
                            mx="auto"
                            mb={3}
                          >
                            <RepUserIcon />
                          </Flex>
                          <Text
                            color="#00377B"
                            fontSize="14px"
                            fontWeight="bold"
                          >
                            {representante.nome}
                          </Text>
                          <Text color="#555" fontSize="14px">
                            {representante.representante_bairro?.nome}
                          </Text>
                          <Text color="#555" fontSize="14px">
                            {`${representante.representante_cidade?.nome}/${representante.representante_estado?.nome}`}
                          </Text>
                          <HStack justifyContent="center" mt={4}>
                            <>
                              {representante.telefone && (
                                <NextLink
                                  href={createWhatsappLink(
                                    representante.telefone,
                                    "Olá, gostaria de mais informações sobre os produtos HERC"
                                  )}
                                  passHref
                                >
                                  <Link
                                    target="_blank"
                                    bgColor="#27AE60"
                                    borderRadius="12px"
                                    w="45px"
                                    h="45px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <RepWhatsIcon />
                                  </Link>
                                </NextLink>
                              )}
                              {representante.email && (
                                <NextLink
                                  href={`mailto:${representante.email}`}
                                  passHref
                                >
                                  <Link
                                    target="_blank"
                                    bgColor="#00377B"
                                    borderRadius="12px"
                                    w="45px"
                                    h="45px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <RepMsgIcon />
                                  </Link>
                                </NextLink>
                              )}
                            </>
                          </HStack>
                        </Box>
                      </Flex>
                    ))}
                </Slider>
              </Flex>
              <ChevronRightIcon
                onClick={() => sliderRef.current.slickNext()}
                color="#007DC5"
                fontSize="3em"
                cursor="pointer"
                opacity={0.5}
                _hover={{ opacity: 1 }}
              />
            </>
          ) : (
            <Grid
              as="section"
              w="full"
              px={["10%", "10%", 0]}
              templateColumns={["1fr", "1fr", "repeat(4, 1fr)"]}
              gap={8}
              mt={4}
            >
              {representantes &&
                representantes.map((representante: RepresentanteProps) => (
                  <GridItem
                    key={representante.id}
                    as="div"
                    w="full"
                    textAlign="center"
                    display="grid"
                    gridTemplateColumns={["1fr 3fr 2fr", "1fr 3fr 2fr", "1fr"]}
                  >
                    <Flex
                      bgColor="#007DC5"
                      borderRadius="full"
                      w={["45px", "45px", "75px"]}
                      h={["45px", "45px", "75px"]}
                      justifyContent="center"
                      alignItems="center"
                      mx="auto"
                      mb={3}
                    >
                      <Box transform={["scale(0.7)", "scale(0.7)", "scale(1)"]}>
                        <RepUserIcon />
                      </Box>
                    </Flex>
                    <Box
                      pl={["8%", "8%", 0]}
                      textAlign={["left", "left", "center"]}
                    >
                      <Text color="#00377B" fontSize="14px" fontWeight="bold">
                        {representante.nome}
                      </Text>
                      <Text color="#555" fontSize="14px">
                        {representante.representante_bairro?.nome}
                      </Text>
                      <Text color="#555" fontSize="14px">
                        {`${representante.representante_cidade?.nome}/${representante.representante_estado?.nome}`}
                      </Text>
                    </Box>
                    <HStack justifyContent="center" mt={[0, 0, 4]}>
                      <>
                        {representante.telefone && (
                          <NextLink
                            href={createWhatsappLink(
                              representante.telefone,
                              "Olá, gostaria de mais informações sobre os produtos HERC"
                            )}
                            passHref
                          >
                            <Link
                              target="_blank"
                              bgColor="#27AE60"
                              borderRadius="12px"
                              w={["40px", "40px", "45px"]}
                              h={["40px", "40px", "45px"]}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <RepWhatsIcon />
                            </Link>
                          </NextLink>
                        )}
                        {representante.email && (
                          <NextLink
                            href={`mailto:${representante.email}`}
                            passHref
                          >
                            <Link
                              target="_blank"
                              bgColor="#00377B"
                              borderRadius="12px"
                              w={["40px", "40px", "45px"]}
                              h={["40px", "40px", "45px"]}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <RepMsgIcon />
                            </Link>
                          </NextLink>
                        )}
                      </>
                    </HStack>
                  </GridItem>
                ))}
            </Grid>
          )}
        </Flex>
      )}
    </>
  );
}
