import { Flex, HStack, Text } from "@chakra-ui/react";

import { IGeneralSettings } from "../../../interfaces/GeneralSettings";
import IconFacebook from "../../../assets/icons/icon-face.svg";
import IconInstagram from "../../../assets/icons/icon-insta.svg";
import IconLinkedin from "../../../assets/icons/icon-linkedin.svg";
import IconPhone from "../../../assets/icons/icon-phone.svg";
import IconTwitter from "../../../assets/icons/icon-tw.svg";
import IconYouTube from "../../../assets/icons/icon-yt.svg";
import Link from "next/link";
import React from "react";

export default function TopBar({ generalSettings }: IGeneralSettings) {
  return (
    <Flex bg="#007DC5" as="section">
      <Flex
        maxW={["80%", "80%", "90%", "90%", "1170px"]}
        flex="1"
        mx="auto"
        justify="space-between"
        py={[1, 1, 0, 0, 0]}
      >
        <HStack
          flexDir={["column", "column", "row"]}
          spacing={[0, 0, 2]}
          alignItems="flex-start"
        >
          {generalSettings.telefones &&
            generalSettings.telefones.map((item) => (
              <Flex
                flexDir="row"
                align={["center"]}
                mr={[0, 0, 4]}
                key={item.id}
              >
                <IconPhone />
                <Flex
                  py={[1, 1, 2]}
                  ml={2}
                  flexDir="row"
                  flex="1"
                  color="white"
                  fontSize="14px"
                >
                  <Text mr={2} fontWeight="bold">
                    {item.nome}
                  </Text>
                  <Link href={`tel:${item.numero}`}>{item.numero}</Link>
                </Flex>
              </Flex>
            ))}
        </HStack>
        <HStack spacing={4} display={["none", "none", "flex"]}>
          {generalSettings.redesSociais &&
            generalSettings.redesSociais.map((item) => (
              <Flex flexDir="row" align="center" key={item.id}>
                <Link href={item.link} passHref>
                  <a target="_blank">
                    {item.nome === "Facebook" && <IconFacebook />}
                    {item.nome === "Instagram" && <IconInstagram />}
                    {item.nome === "YouTube" && <IconYouTube />}
                    {item.nome === "Twitter" && <IconTwitter />}
                    {item.nome === "Linkedin" && <IconLinkedin />}
                  </a>
                </Link>
              </Flex>
            ))}
        </HStack>
      </Flex>
    </Flex>
  );
}
