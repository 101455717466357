import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Representantes from "./Representantes";
import Link from "next/link";
import { getMedia } from "../../lib/media";
import { IGeneralSettings } from "../../interfaces/GeneralSettings";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useModalRepresentante } from "../../provider/modalRepresentantes";
import ResetSearchIcon from "../../assets/icons/icon-reset-search.svg";
import { useMediaQuery } from "react-responsive";

export default function Modal({ generalSettings }: IGeneralSettings) {
  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });

  const {
    isOpen,
    setIsOpen,
    filtered,
    setFiltered,
    totalResult,
    setTotalResult,
    filterEstadoNome,
    setFilterEstadoNome,
    filterCidadeNome,
    setFilterCidadeNome,
    representanteType,
  } = useModalRepresentante();

  const clearFilters = () => {
    setFiltered(false);
    setTotalResult(0);
    setFilterEstadoNome("Todos estados");
    setFilterCidadeNome("Todas cidades");
  };

  return (
    <>
      {isOpen && (
        <Flex
          position="fixed"
          w="full"
          h="100vh"
          // bg={["white", "white", "rgb(0,0,0,0.7)"]}
          zIndex="20"
        >
          <Flex
            w="full"
            flexDir="column"
            position="relative"
            h={["100%", "100%", "min-content", "min-content", "min-content"]}
            bg="white"
            overflowY={["scroll", "scroll", "initial"]}
            zIndex="22"
          >
            {isSmallerThan768 && (
              <>
                <Flex
                  w="full"
                  h="20vh"
                  minH="20vh"
                  justifyContent="space-around"
                  alignItems="center"
                  zIndex="10"
                >
                  <Link href="/" passHref>
                    <a>
                      <img src={getMedia(generalSettings.logo)} />
                    </a>
                  </Link>
                  <Box
                    right={["0", "0", "0", "0", "0", "-50px"]}
                    position="relative"
                  >
                    <CloseIcon
                      cursor="pointer"
                      onClick={() => {
                        setIsOpen(false);
                        clearFilters();
                      }}
                    />
                  </Box>
                </Flex>
                {filtered && (
                  <Flex
                    flexDir="column-reverse"
                    px="10%"
                    mt={6}
                    mb="-4"
                    textAlign="left"
                  >
                    <Box
                      color="gray"
                      borderRadius="8px"
                      border="1px solid"
                      borderColor="gray.200"
                      p="2"
                      mt="2"
                    >
                      {/* <Text>{`${totalResult} resultados para`}</Text> */}
                      <Text fontWeight="bold">{`${filterCidadeNome}/${filterEstadoNome}`}</Text>
                    </Box>
                    <Text
                      as="span"
                      color="gray.600"
                      fontWeight="bold"
                      cursor="pointer"
                      onClick={clearFilters}
                    >
                      {/* <Box mr={2} mb="-4px" display="inline-block">
                        <ResetSearchIcon />
                      </Box> */}
                      Refazer busca
                    </Text>
                  </Flex>
                )}
              </>
            )}
            <Flex
              w={["100%", "100%", "100%", "100%", "1170px"]}
              mx="auto"
              py={8}
              mt={0}
              flexDir={["column", "column", "row"]}
              position="relative"
              zIndex="5"
              px={[0, 0, 10, 10, 0]}
            >
              {!isSmallerThan768 && (
                <Flex
                  w="23%"
                  alignItems="flex-start"
                  justifyContent={filtered ? "space-between" : "center"}
                  flexDir="column"
                  fontSize="13px"
                >
                  <Link href="/" passHref>
                    <a>
                      <img src={getMedia(generalSettings.logo)} />
                    </a>
                  </Link>
                  {filtered && (
                    <>
                      <Box>
                        <Text color="white">{`${totalResult} resultados para`}</Text>
                        <Text
                          color="white"
                          fontWeight="bold"
                        >{`"${filterCidadeNome}/${filterEstadoNome}"`}</Text>
                      </Box>
                      <Text
                        as="span"
                        fontWeight="bold"
                        color="white"
                        cursor="pointer"
                        onClick={clearFilters}
                      >
                        <Box mr={2} mb="-4px" display="inline-block">
                          <ResetSearchIcon />
                        </Box>
                        Refazer busca
                      </Text>
                    </>
                  )}
                </Flex>
              )}

              {representanteType === "loja" ? (
                <Representantes tipo="loja" />
              ) : (
                <Representantes tipo="representante" />
              )}

              {!isSmallerThan768 && (
                <Box
                  right={["0", "0", "0", "0", "0", "-50px"]}
                  position="relative"
                >
                  <CloseIcon
                    cursor="pointer"
                    onClick={() => {
                      setIsOpen(false);
                      clearFilters();
                    }}
                  />
                </Box>
              )}
            </Flex>
            <Flex
              w={["34%", "34%", "34%", "34%", "34%", "38%"]}
              minW={["70%", "70%", "370px", "370px", "370px"]}
              h={["20%", "20%", "100%", "100%", "100%"]}
              bg="radial-gradient(70.5% 104.6% at -1.34% 3.5%, #007DC5 0%, #00377B 100%);"
              position="absolute"
              justifyContent="flex-end"
            >
              <Box
                bgImage="url('/divider-modal-left.png')"
                h={["100%", "100%", "100%", "100%", "100%"]}
                w="167px"
                bgSize="contain"
                bgRepeat="no-repeat"
                bgPosition="right"
                mr="-2px"
              />
            </Flex>
            <Flex
              w="23%"
              h={["20%", "20%", "100%", "100%", "100%"]}
              right="0px"
              bg="#007DC5"
              opacity="0.2"
              position="absolute"
              justifyContent="flex-start"
            >
              <Box
                bgImage="url('/divider-modal-right.png')"
                h={["100%", "100%", "100%", "100%", "100%"]}
                w="167px"
                bgSize="contain"
                bgRepeat="no-repeat"
                bgPosition="left"
                mr="-2px"
              />
            </Flex>
          </Flex>
          <Flex
            position="fixed"
            w="full"
            h="100vh"
            bg={["white", "white", "rgb(0,0,0,0.7)"]}
            zIndex="21"
            onClick={() => {
              setIsOpen(false);
              clearFilters();
            }}
          />
        </Flex>
      )}
    </>
  );
}
