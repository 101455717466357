import { Box, Grid, GridItem, Link, Text } from "@chakra-ui/react";

import { INavFooter } from "../../../interfaces/Navagation";
import NextLink from "next/link";
import React from "react";
import styled from "styled-components";
import { useModalRepresentante } from "../../../provider/modalRepresentantes";

const FooterWrapper = styled.div`
  width: 100%;
  [class^="grid-item"] {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .grid-item-2 {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .grid-item-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`;

export default function NavLinkFooter({ menuFooter }: INavFooter) {
  const { setRepresentanteType, setIsOpen } = useModalRepresentante();

  const openRepresentantesModal = (
    event: React.MouseEvent<HTMLElement>,
    type: any
  ) => {
    event.preventDefault();
    const typeRepresentante = type === "Lojas" ? "loja" : "representante";
    setRepresentanteType(typeRepresentante);
    setIsOpen(true);
  };

  return (
    <>
      <FooterWrapper>
        <Grid
          templateColumns="repeat(6, 1fr)"
          templateRows="repeat(2, 1fr)"
          w="full"
          className="grid-content"
        >
          {menuFooter &&
            menuFooter.map((menu, index) => (
              <GridItem key={menu.id} className={`grid-item-${index + 1}`}>
                {menu.mostrarRotulo && (
                  <Text fontSize="14px" mb={2} color="white" fontWeight="bold">
                    {menu.rotulo}
                  </Text>
                )}
                <Box as="ul">
                  {menu.menuItens &&
                    menu.menuItens.map((submenu, index) => (
                      <Box as="li" mb={2} listStyleType="none" key={submenu.id}>
                        <NextLink
                          href={
                            submenu.linkExterno
                              ? submenu.link!
                              : `/${submenu.pagina?.slug!}${
                                  submenu.ancora ? `#${submenu.ancora}` : ""
                                }`
                          }
                          passHref
                        >
                          {submenu.estilo === "Link" ? (
                            <Link
                              display="block"
                              fontSize="12px"
                              color="white"
                              target={
                                submenu.linkExterno &&
                                !submenu.link?.startsWith("/")
                                  ? "_blank"
                                  : "_self"
                              }
                              onClick={
                                submenu.nome === "Representantes" ||
                                submenu.nome === "Lojas"
                                  ? (event) =>
                                      openRepresentantesModal(
                                        event,
                                        submenu.nome
                                      )
                                  : undefined
                              }
                            >
                              {submenu.nome}
                            </Link>
                          ) : (
                            <Link
                              target={submenu.linkExterno ? "_blank" : "_self"}
                              onClick={
                                submenu.nome === "Representantes" ||
                                submenu.nome === "Lojas"
                                  ? (event) =>
                                      openRepresentantesModal(
                                        event,
                                        submenu.nome
                                      )
                                  : undefined
                              }
                              display="inline-block"
                              fontSize="12px"
                              color="#007DC5"
                              bgColor="white"
                              borderRadius="8px"
                              py={2}
                              px={4}
                              mt={1}
                            >
                              {submenu.nome}
                            </Link>
                          )}
                        </NextLink>
                      </Box>
                    ))}
                </Box>
              </GridItem>
            ))}
        </Grid>
      </FooterWrapper>
    </>
  );
}
