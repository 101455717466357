import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { INavegation } from "../../../interfaces/Navagation";
import NextLink from "next/link";
import { getParentPageData } from "../../../lib/api";
import styled from "styled-components";
import { useModalRepresentante } from "../../../provider/modalRepresentantes";
import { useRouter } from "next/router";

const FlexStyle = styled(Flex)`
  a.active {
    border-color: #ed1b2f;
    font-weight: bold;
    border-bottom: 3px solid #ed1b2f;
  }
`;
export default function Navegation({ menu }: INavegation) {
  const { representanteType, setRepresentanteType, setIsOpen } =
    useModalRepresentante();
  const { asPath, pathname } = useRouter();
  const openRepresentantesModal = (type: "loja" | "representante") => {
    setRepresentanteType(type);
    setIsOpen(true);
    // router.push({ pathname: "/onde-encontrar" });
  };

  const [pageBlog, setPageBlog] = useState(false);
  useEffect(() => {
    setPageBlog(asPath.includes("conteudo"));
  }, [asPath]);

  const [pageParent, setPageParent] = useState<any>();

  useEffect(() => {
    const getParent = async () => {
      const pageParent = await getParentPageData(asPath.replace("/", ""));
      console.log("Path:", asPath);
      console.log(pageParent);
      setPageParent(pageParent);
    };
    getParent();
  }, [asPath, pathname]);
  return (
    <FlexStyle
      boxShadow="0 4px 4px #0000001a"
      display={["none", "none", "flex"]}
    >
      <Flex
        as="nav"
        flex="1"
        alignItems="center"
        justifyContent="space-between"
        maxW="1170px"
        mx="auto"
      >
        <HStack as="ul" width="full" justifyContent="space-between">
          {menu &&
            menu.map((item) => (
              <Box as="li" key={item.id} listStyleType="none">
                {item.titulo === "Onde Encontrar" ? (
                  <Menu>
                    <MenuButton
                      as={Link}
                      fontSize="14px"
                      color="#00377B"
                      py={2}
                      px={2}
                      display="block"
                      borderBottom="3px solid #FFF"
                      _hover={{
                        outline: "none",
                        borderColor: " #ED1B2F",
                        fontWeight: "bold",
                      }}
                    >
                      Onde Encontrar
                    </MenuButton>
                    <MenuList
                      zIndex="10"
                      mt="-8px"
                      ml="-18px"
                      minW="150px"
                      w="150px"
                      borderRadius="lg"
                      borderTopRadius="0"
                      border="none"
                    >
                      <MenuItem onClick={() => openRepresentantesModal("loja")}>
                        Lojas
                      </MenuItem>
                      <MenuItem
                        onClick={() => openRepresentantesModal("representante")}
                      >
                        Representantes
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <NextLink
                    href={
                      item.link_externo
                        ? String(item.link)
                        : `/${item.pagina.slug}`
                    }
                    passHref
                  >
                    <Link
                      className={
                        pathname.replace("/", "") === item.pagina.slug ||
                        asPath.replace("/", "") === item.pagina.slug ||
                        pageParent?.paginaAscendente?.titulo === item.titulo ||
                        (pageBlog && item.titulo === "Conteúdo")
                          ? "active"
                          : ""
                      }
                      fontSize="14px"
                      color="#00377B"
                      py={2}
                      px={2}
                      display="block"
                      borderBottom="3px solid #FFF"
                      _hover={{
                        outline: "none",
                        borderColor: " #ED1B2F",
                        fontWeight: "bold",
                      }}
                      target={item.link_externo ? "_blank" : ""}
                    >
                      {item.titulo}
                    </Link>
                  </NextLink>
                )}
              </Box>
            ))}
        </HStack>
      </Flex>
    </FlexStyle>
  );
}
