import "@fontsource/fira-sans";
import { ChakraProvider } from "@chakra-ui/react";
import type { AppContext, AppProps } from "next/app";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Layout from "../components/Layout";
import { IGeneralSettings } from "../interfaces/GeneralSettings";
import { INavegation, INavFooter } from "../interfaces/Navagation";
import theme from "../theme/theme";
import fetchAPI from "../lib/api";
import GlobalStyle from "../theme/globalStyles";
import Script from "next/script";
import { DefaultSeo } from "next-seo";

const queryClient = new QueryClient();
function MyApp({
  Component,
  pageProps,
  generalSettings,
  menu,
  menuFooter,
}: AppProps & IGeneralSettings & INavegation & INavFooter) {
  const googleCode = "UA-15672096-29";
  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${googleCode}`}
      />
      <Script strategy="lazyOnload">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${googleCode}', {
                    page_path: window.location.pathname,
                    });
                    gtag('config', 'AW-931807544');
                `}
      </Script>

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
          (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/56c232fe589e851628b51e5b/1ed4spjdm';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
          })();
        `,
        }}
      />

      <DefaultSeo
        title="HERC"
        description="HERC. Há mais de 50 anos, a marca que identifica inovação e qualidade em produtos plásticos no Brasil e no mundo."
        openGraph={{
          type: "website",
          locale: "pt_BR",
          url: "https://herc.com.br/",
          site_name: "Herc",
        }}
      />

      <ChakraProvider theme={theme}>
        <GlobalStyle />

        <QueryClientProvider client={queryClient}>
          <Layout
            generalSettings={generalSettings}
            menu={menu}
            menuFooter={menuFooter}
          >
            <Component {...pageProps} />
          </Layout>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}
export default MyApp;

MyApp.getInitialProps = async (appContext: AppContext) => {
  const generalSettings: IGeneralSettings = await fetchAPI(
    "/configuracoes-gerais"
  );
  const navegationItems: INavegation = await fetchAPI("/menu-principal");
  const navegationFooter: INavegation = await fetchAPI("/menu-rodape");
  return {
    generalSettings,
    menu: navegationItems.menu,
    menuFooter: navegationFooter.menu,
  };
};
