import { Box, Flex, Heading, HStack, Link, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import NextLink from "next/link";
import { useMediaQuery } from "react-responsive";
import { getMedia } from "../../lib/media";
import { INavFooter } from "../../interfaces/Navagation";
import { IGeneralSettings } from "../../interfaces/GeneralSettings";
import IconFacebook from "../../assets/icons/icon-face.svg";
import IconInstagram from "../../assets/icons/icon-insta.svg";
import IconLinkedin from "../../assets/icons/icon-linkedin.svg";
import IconTwitter from "../../assets/icons/icon-tw.svg";
import IconYouTube from "../../assets/icons/icon-yt.svg";
import NavLinkFooter from "./NavLinkFooter/NavLinkFooter";
import NavLinkFooterMobile from "./NavLinkFooter/NavLinkFooterMobile";

export default function Footer({
  generalSettings,
  menuFooter,
}: IGeneralSettings & INavFooter) {
  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  useEffect(() => {
    if (isSmallerThan768) {
      setShowMobileMenu(true);
    }
  }, [isSmallerThan768]);

  return (
    <div>
      <Flex
        as="footer"
        bgImage={[
          "/bg-rodape-mobile-v2.png",
          "/bg-rodape-mobile-v2.png",
          "/bg-rodape-full.png",
        ]}
        bgPos="left center"
        bgSize="cover"
        bgRepeat="no-repeat"
        minHeight={["430px", "430px", "430px", "514px"]}
        w="full"
        mt={["-150px", "-150px", "-200px"]}
        backgroundPosition={["top center", "top center", "left center"]}
      >
        <Flex
          w="full"
          maxW={["80%", "80%", "90%", "90%", "1170px"]}
          mx="auto"
          alignItems="flex-end"
          pb="40px"
          mt={["60", "60", "0"]}
        >
          <Flex
            justifyContent="space-between"
            width="100%"
            flexDir={["column", "column", "row"]}
          >
            <Flex
              flexDir="column"
              justifyContent={[
                "space-between",
                "space-between",
                "space-between",
                "center",
              ]}
              pr={[0, 0, 0, "40px"]}
              w={["100%", "100%", "60%", "15%"]}
              mx="auto"
            >
              <Flex
                justifyContent={["center", "center", "space-between"]}
                w="100%"
              >
                <NextLink href="/" passHref>
                  <Link>
                    <img
                      src={getMedia(generalSettings.logo)}
                      style={{ margin: "0 auto" }}
                    />
                  </Link>
                </NextLink>
              </Flex>
              <HStack spacing={3} mt={[6, 6, 6, 4]}>
                {generalSettings.redesSociais &&
                  generalSettings.redesSociais.map((item) => (
                    <Flex
                      flexDir="row"
                      justifyContent={["center"]}
                      w="full"
                      key={item.id}
                    >
                      <NextLink href={item.link} passHref>
                        <Link target="_blank">
                          {item.nome === "Facebook" && <IconFacebook />}
                          {item.nome === "Instagram" && <IconInstagram />}
                          {item.nome === "YouTube" && <IconYouTube />}
                          {item.nome === "Twitter" && <IconTwitter />}
                          {item.nome === "Linkedin" && <IconLinkedin />}
                        </Link>
                      </NextLink>
                    </Flex>
                  ))}
              </HStack>
            </Flex>

            {showMobileMenu ? (
              <Flex w="full">
                <NavLinkFooterMobile menuFooter={menuFooter} />
              </Flex>
            ) : (
              <Flex w="85%" display={"flex"} justifyContent="space-between">
                <NavLinkFooter menuFooter={menuFooter} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}
