import React from "react";
import {
  Box,
  Flex,
  FlexProps,
  StackProps,
  HStack,
  Link,
  VStack,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { INavegation } from "../../../interfaces/Navagation";
import { CloseIcon } from "@chakra-ui/icons";
import { AnimatePresence, motion } from "framer-motion";
import { IGeneralSettings } from "../../../interfaces/GeneralSettings";
import IconFacebook from "../../../assets/icons/icon-face-blue.svg";
import IconInstagram from "../../../assets/icons/icon-insta-blue.svg";
import IconLinkedin from "../../../assets/icons/icon-linkedin-blue.svg";
import IconTwitter from "../../../assets/icons/icon-tw-blue.svg";
import IconYouTube from "../../../assets/icons/icon-yt-blue.svg";
import IconArrowRight from "../../../assets/icons/icon-arrow-right.svg";
import { useModalRepresentante } from "../../../provider/modalRepresentantes";

interface Props {
  isOpenMenu: boolean;
  setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const MotionFlex = motion<FlexProps>(Flex);
const MotionVStack = motion<StackProps>(VStack);
const representantesMenuEffect = {
  hidden: {
    opacity: 0,
    height: 0,
    margim: 0,
  },
  visible: {
    opacity: 1,
    height: "auto",
  },
};

export default function MobileMenu({
  menu,
  generalSettings,
  isOpenMenu,
  setIsOpenMenu,
}: INavegation & IGeneralSettings & Props) {
  const { setRepresentanteType, setIsOpen } = useModalRepresentante();
  const [isOpenRepresentantes, setIsOpenRepresentantes] = React.useState(false);

  const handleRepresentantesClick = (open: boolean) => {
    setIsOpenRepresentantes(open);
  };

  const openRepresentantesModal = (type: "loja" | "representante") => {
    setIsOpenMenu(false);
    setRepresentanteType(type);
    setIsOpen(true);
    setIsOpenRepresentantes(false);
  };

  return (
    <AnimatePresence>
      {isOpenMenu && (
        <MotionFlex
          bgColor="rgb(0,0,0,0.2)"
          h="full"
          w="full"
          pos="fixed"
          top="0"
          right="0"
          zIndex="99"
          display={["flex", "flex", "none"]}
          justifyContent="flex-end"
          overflowY="hidden"
          initial={{ right: "-100%", opacity: 0 }}
          animate={{ right: 0, opacity: 1 }}
          exit={{ right: "-100%", opacity: 0 }}
        >
          <Flex
            bgColor="white"
            w="85%"
            h="full"
            flexDir="column"
            justifyContent="space-between"
            p="30px 30px"
          >
            <Flex flexDir="column">
              <Flex mb={6} justifyContent="space-between">
                <Text fontWeight="bold" pl={2}>
                  Menu
                </Text>
                <CloseIcon
                  color="#555"
                  alignSelf="end"
                  mb={2}
                  onClick={() => setIsOpenMenu(false)}
                />
              </Flex>
              <VStack
                as="ul"
                width="full"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={9}
              >
                {menu &&
                  menu.map((item) => (
                    <Box as="li" key={item.id} listStyleType="none">
                      {item.titulo === "Onde Encontrar" ? (
                        <>
                          <Box
                            fontSize="14px"
                            color="#00377B"
                            px={2}
                            display="block"
                            borderBottom="3px solid #FFF"
                            _hover={{
                              outline: "none",
                              borderColor: " #ED1B2F",
                              fontWeight: "bold",
                            }}
                          >
                            <Text
                              onClick={() =>
                                handleRepresentantesClick(!isOpenRepresentantes)
                              }
                            >
                              {item.titulo}
                            </Text>
                          </Box>
                          <MotionVStack
                            mt={isOpenRepresentantes ? 4 : 0}
                            alignItems="left"
                            overflow="hidden"
                            animate={
                              isOpenRepresentantes ? "visible" : "hidden"
                            }
                            variants={representantesMenuEffect}
                          >
                            <Flex
                              alignItems="center"
                              mb={2}
                              onClick={() => openRepresentantesModal("loja")}
                            >
                              <IconArrowRight />
                              <Text ml="2">Lojas</Text>
                            </Flex>
                            <Flex
                              alignItems="center"
                              onClick={() =>
                                openRepresentantesModal("representante")
                              }
                            >
                              <IconArrowRight />
                              <Text ml="2">Representantes</Text>
                            </Flex>
                          </MotionVStack>
                        </>
                      ) : (
                        <NextLink href={`/${item.pagina.slug}`} passHref>
                          <Link
                            fontSize="14px"
                            color="#00377B"
                            px={2}
                            display="block"
                            borderBottom="3px solid #FFF"
                            textAlign="left"
                            _hover={{
                              outline: "none",
                              borderColor: " #ED1B2F",
                              fontWeight: "bold",
                            }}
                            onClick={() => setIsOpenMenu(false)}
                          >
                            {item.titulo}
                          </Link>
                        </NextLink>
                      )}
                    </Box>
                  ))}
              </VStack>
            </Flex>
            <HStack justifyContent="space-between" display={["flex"]}>
              {generalSettings.redesSociais &&
                generalSettings.redesSociais.map((item) => (
                  <Flex flexDir="row" align="center" key={item.id}>
                    <NextLink href={item.link} passHref>
                      <Link target="_blank">
                        {item.nome === "Facebook" && <IconFacebook />}
                        {item.nome === "Instagram" && <IconInstagram />}
                        {item.nome === "YouTube" && <IconYouTube />}
                        {item.nome === "Twitter" && <IconTwitter />}
                        {item.nome === "Linkedin" && <IconLinkedin />}
                      </Link>
                    </NextLink>
                  </Flex>
                ))}
            </HStack>
          </Flex>
        </MotionFlex>
      )}
    </AnimatePresence>
  );
}
