import React, { useState } from "react";

interface PropsModalContext {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  filtered: boolean;
  setFiltered: (filtered: boolean) => void;
  totalResult: number;
  setTotalResult: (totalResult: number) => void;
  filterEstadoNome: string;
  setFilterEstadoNome: (filterEstado: string) => void;
  filterCidadeNome: string;
  setFilterCidadeNome: (filterEstado: string) => void;
  representanteType: string;
  setRepresentanteType: (representanteType: "representante" | "loja") => void;
}

const DEFAULT_VALUE: PropsModalContext = {
  isOpen: false,
  setIsOpen: () => {},
  filtered: false,
  setFiltered: () => {},
  totalResult: 0,
  setTotalResult: () => {},
  filterEstadoNome: "",
  setFilterEstadoNome: () => {},
  filterCidadeNome: "",
  setFilterCidadeNome: () => {},
  representanteType: "loja",
  setRepresentanteType: () => {},
};

export const ModalRepresentanteContext =
  React.createContext<PropsModalContext>(DEFAULT_VALUE);

export const ModalRepresentanteProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [filterEstadoNome, setFilterEstadoNome] = useState("Todos estados");
  const [filterCidadeNome, setFilterCidadeNome] = useState("Todas cidades");
  const [representanteType, setRepresentanteType] = useState("loja");

  return (
    <ModalRepresentanteContext.Provider
      value={{
        isOpen,
        setIsOpen,
        filtered,
        setFiltered,
        totalResult,
        setTotalResult,
        filterEstadoNome,
        setFilterEstadoNome,
        filterCidadeNome,
        setFilterCidadeNome,
        representanteType,
        setRepresentanteType,
      }}
    >
      {props.children}
    </ModalRepresentanteContext.Provider>
  );
};

export const useModalRepresentante = () =>
  React.useContext(ModalRepresentanteContext);
