import React from "react";
import {
  Flex,
  Text,
  Box,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { INavFooter } from "../../../interfaces/Navagation";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useModalRepresentante } from "../../../provider/modalRepresentantes";

export default function NavLinkFooterMobile({ menuFooter }: INavFooter) {
  const { setRepresentanteType, setIsOpen } = useModalRepresentante();

  const openRepresentantesModal = (
    event: React.MouseEvent<HTMLElement>,
    type: any
  ) => {
    event.preventDefault();
    const typeRepresentante = type === "Lojas" ? "loja" : "representante";
    setRepresentanteType(typeRepresentante);
    setIsOpen(true);
  };

  return (
    <Flex w="full">
      <Accordion allowToggle w="full" mt={4}>
        {menuFooter &&
          menuFooter.map((menu, index) => (
            <AccordionItem key={menu.id} border="none">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      px="none"
                      py={3}
                      _expanded={{
                        background: "transparent",
                        boxShadow: "none",
                        outline: "none",
                      }}
                    >
                      <Box flex="1" textAlign="left">
                        <Text fontSize="18px" color="white" fontWeight="bold">
                          {menu.rotulo}
                        </Text>
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" color="white" />
                      ) : (
                        <AddIcon fontSize="12px" color="white" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={3}>
                    <Box as="ul">
                      {menu.menuItens &&
                        menu.menuItens.map((submenu) => (
                          <Box
                            as="li"
                            mb={3}
                            listStyleType="none"
                            key={submenu.id}
                          >
                            <NextLink
                              href={
                                submenu.linkExterno
                                  ? submenu.link!
                                  : `/${submenu.pagina?.slug!}${
                                      submenu.ancora ? `#${submenu.ancora}` : ""
                                    }`
                              }
                              passHref
                            >
                              <Link
                                display="block"
                                fontSize="16px"
                                color="white"
                                target={
                                  submenu.linkExterno ? "_blank" : "_self"
                                }
                                onClick={
                                  submenu.nome === "Representantes" ||
                                  submenu.nome === "Lojas"
                                    ? (event) =>
                                        openRepresentantesModal(
                                          event,
                                          submenu.nome
                                        )
                                    : undefined
                                }
                              >
                                {submenu.nome}
                              </Link>
                            </NextLink>
                          </Box>
                        ))}
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
      </Accordion>
    </Flex>
  );
}
