import delve from "dlv";

export function getStrapiURL(path: string = "") {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
  }${path}`;
}

export default async function fetchAPI(path: string) {
  const requestURL = getStrapiURL(path);
  const response = await fetch(requestURL);
  const data = await response.json();
  return data;
}
// handle the redirection to the homepage if the page we are browsinng doesn't exists
export function redirectToHomepage() {
  return {
    redirect: {
      destination: `/`,
      permanent: false,
    },
  };
}

// This function will build the url to fetch on the Strapi API
export function getData(slug: string, locale: string) {
  const slugToReturn = `/${slug}?lang=${locale}`;
  const apiUrl = `/paginas?slug=${slug}&_locale=${locale}`;

  return {
    data: getStrapiURL(apiUrl),
    slug: slugToReturn,
  };
}

// This function will build the url to fetch on the Strapi API
export function getDataPosts(slug: string, locale: string) {
  const slugToReturn = `/${slug}`;
  const apiUrl = `/blogs?slug=${slug}`;

  return {
    data: getStrapiURL(apiUrl),
    slug: slugToReturn,
  };
}

// This function will get the data dependencies for every blocks.
export async function getDataDependencies(json: object) {
  let blocks = delve(json, "block", []);
  blocks = await Promise.all(blocks.map(checkRequiredData));

  return {
    ...json,
    block: blocks,
  };
}
export async function getDataDependenciesPosts(json: object) {
  let blocks = delve(json, "conteudoPost", []);
  blocks = await Promise.all(blocks.map(checkRequiredData));

  return {
    ...json,
    block: blocks,
  };
}

// This functionn can merge required data but it is not used here.
interface BlockProps {
  __component: string;
}
export async function checkRequiredData(block: BlockProps | any) {
  let newBlock = block;

  switch (block.__component) {
    case "blocks.carrossel-categorias":
      newBlock = blockCarrousselCategories(block);
      break;
    case "blocks.menu-interno-pagina":
      newBlock = blockMenuInternoPagina(block);
      break;
    case "blocks.area-downloads":
      newBlock = blockDownloadArea(block);
      break;
  }

  return newBlock;
}

async function blockCarrousselCategories(block: any) {
  if (block.mostrarTodos) {
    const result = await fetchAPI("/categorias");
    return { ...block, categorias: result };
  }
  return block;
}

async function blockMenuInternoPagina(block: any) {
  if (block.pagina_ascendente) {
    const result = await fetchAPI(
      `/paginas?paginaAscendente=${block.pagina_ascendente.id}&_sort=ordem:ASC`
    );
    return { ...block, menuItems: result };
  }
  return block;
}

async function blockDownloadArea(block: any) {
  const downloadsCategories = await fetchAPI(
    "/download-categorias?_sort=ordem:ASC"
  );
  const downloads = await fetchAPI("/downloads");
  return { ...block, downloadsCategories, downloads };

  return block;
}

export async function getParentPageData(path: string) {
  const parentPage = await fetchAPI(`/paginas?slug=${path}`);
  return parentPage.length > 0 ? parentPage[0] : null;
}
