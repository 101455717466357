import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
 .page-slug-home{
   background: url("/bg-meio.png");
   background-position: center 50%;
   background-repeat: no-repeat;
   background-size: contain;
 }
 .page-slug-contato{
   background: url("/bg-meio.png");
   background-position: center 40%;
   background-repeat: no-repeat;
   background-size: contain;
 }

 /* Pagination */
 .paginate-container ul {
  display: inline-block;
  padding-left: 18px;
  padding-right: 18px;
}

.paginate-container li {
  display: inline-block;
  margin: 5px 15px;
  font-size: 14px;
  font-family: var(--chakra-fonts-body);
  color: #555555;
}

.paginate-container li a {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.paginate-container li.selected a, .paginate-container li a:hover{
  color: #FFF;
  background: #ED1B2F;
}
 /* Pagination */
`;

export default GlobalStyle;
