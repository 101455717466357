import { IMedia } from "../interfaces/Media";
import { getStrapiURL } from "./api";

export function getMedia(media: IMedia, size?: string) {
  if (typeof media !== "undefined") {
    if (media == null) {
      return "";
    }

    let mediaUrl;
    switch (size) {
      case "large":
        mediaUrl = media?.formats?.large?.url;
        break;
      case "medium":
        mediaUrl = media?.formats?.medium?.url;
        break;
      case "small":
        mediaUrl = media?.formats?.small?.url;
        break;
      case "thumbnail":
        mediaUrl = media?.formats?.thumbnail?.url;
        break;
      default:
        mediaUrl = media.url;
        break;
    }

    const imageUrl = mediaUrl?.startsWith("/")
      ? getStrapiURL(mediaUrl)
      : mediaUrl;
    return imageUrl;
  }
}
